
import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/Header.vue';
import ImageOverCard from '@/components/ImageOverCard.vue';
import { Get404PageResponse } from '@/plugins/api';
import date from '@/mixins/date';
@Component({
  filters: {
    date
  },
  components: {
    Header,
    card: ImageOverCard
  },
})
export default class LandingIndex extends Vue {
  private readonly card_details = [
    {title: 'جدیدترین', subtitle: 'لیست جدیدترین قرعه کشی های ایجاد شده', image: require('@/assets/images/instagram.png')},
    {title: 'جدیدترین', subtitle: 'لیست برندگان آخرین قرعه‌کشی برگزار شده', image: require('@/assets/images/trophy.png')},
    {title: 'در حال برگزاری', subtitle: 'لیست آخرین قرعه کشی های در حال برگزاری', image: require('@/assets/images/gift.png')},
  ];
  private data: Get404PageResponse|null = null;
  private async getData() {
    const res = await this.$api.get404Data();
    this.data = res.data;
  }

  mounted () {
    this.getData();
  }
}
